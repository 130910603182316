import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Component Imports
import TagList from '../tag-list';
import Author from '../author';

// Style Imports
import '../../sass/components/article-card.scss';

const ArticleCard = ({
  slug,
  title,
  date,
  description,
  author,
  tags,
}) => {
  return (
    <li className='article-card'>
      <article className='article-card__article'>
        <h2 className='article-card__heading'>
          <Link className='article-card__heading-link' to={`/articles/${slug}`}>
            {title}
          </Link>
        </h2>

        <Author
          author={author}
          date={date}
        />

        <p className='article-card__description'>
          {description}... <Link className='article-card__link' to={`/articles/${slug}`}>Read more</Link>
        </p>

        {tags.length && (
          <TagList
            tags={tags}
            prepend='Tags:'
          />
        )}
      </article>
    </li>
  );
};

ArticleCard.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
};

export default ArticleCard;