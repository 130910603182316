import React, { useState } from 'react';
import { graphql } from 'gatsby';

// Layout Imports
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Component Imports
import PageHeader from '../../components/page-header';
import Section from '../../components/section';
import ArticleList from "../../components/article-list";
import InputField from '../../components/input-field';
import Pagination from '../../components/pagination';

// Asset Imports
import ArticleImage from '../../assets/images/content/blog-post.svg';

// Style Imports
import '../../sass/components/article-search.scss';

const Articles = (props) => {
  const [query, setQuery] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);

  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/articles' : `/articles/${(currentPage - 1).toString()}`;
  const nextPage = `/articles/${(currentPage + 1).toString()}`;

  const { edges: posts } = props.data.allMarkdownRemark;

  const handleSearch = (query) => {
    setQuery(query);

    const filteredData = posts.filter(post => {
      const { description, title, tags } = post.node.frontmatter;
      const descriptionIncludesQuery = description.toLowerCase().includes(query.toLowerCase());
      const titleIncludesQuery = title.toLowerCase().includes(query.toLowerCase());
      const tagsIncludeQuery = tags && tags.join("").toLowerCase().includes(query.toLowerCase());

      return descriptionIncludesQuery || titleIncludesQuery ||tagsIncludeQuery;
    });

    setFilteredPosts(filteredData);
  };

  const results = query ? filteredPosts : posts;

  return (
    <Default>
      <Seo title='Articles' />

      <PageHeader
        heading='Read our latest articles'
        imageSource={ArticleImage}
        description='Keep up to date with the latest news and website updates'
      />
      
      <Section
        label='Article listing'
        variants={['padded']}
      >
        <div className='article-search'>
          <div className='article-search__controls'>
            <InputField
              className='article-search__input'
              id='search'
              type='search'
              labelText='Search articles:'
              placeholder='Search...'
              value={query}
              onChange={e => {
                handleSearch(e.target.value);
              }}
            />
          </div>

          {!!!results.length ? (
            <p>No results found - try a different search term!</p>
          ) : (
            <ArticleList posts={results} />
          )}
        </div>

        <Pagination
          isFirst={isFirst}
          prevPage={prevPage}
          currentPage={currentPage}
          isLast={isLast}
          nextPage={nextPage}
          numPages={numPages}
        />
      </Section>
    </Default>
  );
};

export default Articles;

export const ArticlesQuery = graphql`
  query Articles($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            tags
            path
            templateKey
          }
        }
      }
    }
  }
`;