import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/author.scss';

// Asset Imports
import James from '../../assets/images/content/james.png';

const Author = ({
  date,
  author,
}) => {
    return (
        <div className='author'>
            <div className='author__image-wrapper'>
                <img className='author__image' src={James} alt='' aria-hidden='true' />
            </div>

            <p className='author__text'>by {author}<span className='author__date'>{date}</span></p>
        </div>
    );
};

Author.propTypes = {
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default Author;