import React from 'react';
import PropTypes from 'prop-types';

// Components
import ArticleCard from '../article-card';

// Style Imports
import '../../sass/components/article-list.scss';

const ArticleList = ({
  posts,
}) => {
  return (
    <ul className='article-list'>
      {posts?.map(({ node: post }, index) => (
        <ArticleCard
          date={post.frontmatter.date}
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          slug={post.frontmatter.path}
          author={post.frontmatter.author}
          tags={post.frontmatter.tags}
          key={index}
        />
      ))}
    </ul>
  );
};

ArticleList.propTypes = {
  posts: PropTypes.any.isRequired,
};

export default ArticleList;